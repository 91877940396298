import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import Copyright from 'src/components/molecules/Copyright'
import getFunctionUrl from 'src/utils/getFunctionUrl'

export default function MigrationView() {
  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        {/*<MigrationTool*/}
        {/*  migration={'1615286360'}*/}
        {/*  number={'1'}*/}
        {/*  title={'Flag "visible"'}*/}
        {/*  content={'Ajouter un flag "visible" = true là où cela est nécessaire. (19.02.2021)'}*/}
        {/*/>*/}

        {/*<MigrationTool*/}
        {/*  migration={'1615286363'}*/}
        {/*  number={'2'}*/}
        {/*  title={'Normaliser les "users"'}*/}
        {/*  content={*/}
        {/*    'Ajouter une valeur vide pour les champs "firstName", "lastName" et "email" à la place de "undefined" (09.03.2021)'*/}
        {/*  }*/}
        {/*/>*/}

        {/*<MigrationTool*/}
        {/*  migration={'1624011396'}*/}
        {/*  number={'3 - 3.0'}*/}
        {/*  title={'Méthode de paiement'}*/}
        {/*  content={*/}
        {/*    "Ajouter l'information de la méthode de paiement dans les données existantes (19.06.2021)"*/}
        {/*  }*/}
        {/*/>*/}

        {/*<MigrationTool*/}
        {/*  migration={'1626425159'}*/}
        {/*  number={'4 - 3.0'}*/}
        {/*  title={'Normaliser les "deals"'}*/}
        {/*  content={'Ajouter les champs "merchantName" et "categoryName" (16.07.2021)'}*/}
        {/*/>*/}

        {/*<MigrationTool*/}
        {/*  migration={'1626686701'}*/}
        {/*  number={'5 - 3.0'}*/}
        {/*  title={'Normaliser les "sales"'}*/}
        {/*  content={*/}
        {/*    'Pointer les ventes vers le bon deal (deals, archivedDeals, deletedDeals) (19.07.2021)'*/}
        {/*  }*/}
        {/*/>*/}

        <MigrationTool
          migration={'1615286360'}
          number={'6'}
          title={'Normaliser les "subsidiaries"'}
          content={
            "Traiter les points de ventes comme des sous-collections d'un marchant(18.07.2021)"
          }
        />

        <MigrationTool
          migration={'1629991972'}
          number={'7'}
          title={'Normaliser les "ventes"'}
          content={'Ajouter le nom et prénom du client (18.07.2021)'}
        />

        <MigrationTool
          migration={'1662541480'}
          number={'8'}
          title={'Update des ventes'}
          content={'Ajouter isMerchantNotified à true (07.09.2022)'}
        />
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

type MigrationToolType = {
  migration: string
  number: string
  title: string
  content: string
}

const MigrationTool = ({ migration, number, title, content }: MigrationToolType) => {
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')

  const handleMigration = async () => {
    setResultSuccess('')
    setResultError('')
    setIsSubmitting(true)
    try {
      const url = getFunctionUrl(`migrateData${migration}`)
      const result = await fetch(url, {
        headers: { 'Content-Type': 'application/json' }
      })
      setResultSuccess(JSON.stringify(await result.json(), null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(e.message)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <Paper className={'classes.paper'} style={{ padding: '16px' }}>
          <Typography variant="h6" color="textSecondary">
            <Badge
              badgeContent={number}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}>
              {title}
            </Badge>
          </Typography>
          <div className={classes.container}>{content}</div>
          <div className={classes.container}>
            <Button
              disabled={isSubmitting}
              onClick={handleMigration}
              variant="contained"
              color="primary">
              Exécuter migration
            </Button>
          </div>

          {isSubmitting && <CircularProgress className={classes.loading} />}
          {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
          {resultError && <Box bgcolor="error.main">{resultError}</Box>}
        </Paper>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
